<script>
import Form from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('backstage_supermarket_manage_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.get('/kms/kmsdirectsystem/query', { id: this.formConfig.id }).then((res) => {
        const arr = [];
        res.result.orderTypeVos.map((v) => {
          const item = v;
          arr.push(item.orderType);
          return item;
        });
        this.setValue({
          ...res.result,
          orderTypeVos: arr,
        });
        this.row = res.result;
      });
    }
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'orderTypeVos') {
        // 单据类型
        item.restful = '/mdm/mdmdictdata/list';
        item.restfulParams = {
          dictTypeCode: 'kms_order_type',
        };
        item.optionsKey = { label: 'dictValue', value: 'dictCode' };
        item.refresh = true;
      }
      return item;
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      const orderTypeVos = this.getRule('orderTypeVos');
      const arr = [];
      orderTypeVos.options.map((v) => {
        const item = v;
        const index = formData.orderTypeVos.findIndex((val) => val === item.value);
        if (index > -1) {
          arr.push({
            orderType: item.value,
            orderTypeName: item.label,
          });
        }
        return item;
      });
      const param = {
        ...this.row,
        ...formData,
        orderTypeVos: arr,
      };
      request.post(this.formConfig.id ? ('/kms/kmsdirectsystem/update') : ('/kms/kmsdirectsystem/save'), param).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
